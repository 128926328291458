@import '~@/assets/scss/main.scss';

.my-progress-container {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
    margin-top: 20px;

    .my-progress-filter {
        max-width: 20rem;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -30px;
        margin-bottom: 20px;
    }
}

#rank-card,
.my-progress-container {
    select {
        height: 2.75rem;
        font-size: 1.25rem;
        @extend .font-raleway;
    }
}

.sticker-sizing {
    $size: 8rem;
    height: $size;
    width: $size;
}
