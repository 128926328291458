@import '~@/assets/scss/main.scss';

.gauge-wrapper {
    min-height: 10rem;
}

.sticker-sizing {
    $size: 8rem;
    height: $size;
    width: $size;
}

.gauge-center {
    margin-bottom: 0rem;
    font-size: 1.25rem;
    @extend .font-raleway;
    @extend .font-weight-bold;
}
